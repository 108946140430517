.counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
}

.counter button{
    border: none;
    background: transparent;
}

.counter img{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
