.container {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bag {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
    margin: 0 auto;
    width: 80vw;
}

.container h2 {
    text-align: center;
    font-size: 2.5rem;
    letter-spacing: 1px;
}

.total {
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    align-items: center;
}

.clear {
    align-self: center;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    background-color: #f0c040;
    font-size: 1rem;
    color: darkblue;
    cursor: pointer;
}

.totalPrice {
    font-weight: bold;
    background: mediumslateblue;
    color: white;
    min-width: 90px;
    padding: 0 5px;
    text-align: left;
    border-radius: 5px;
}

.empty {
    text-align: center;
    font-size: 2rem;
    letter-spacing: 1px;
    color: rgb(128, 128, 128);
}
