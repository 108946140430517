.header {
    display: flex;
    justify-content: space-between;
    padding: 1.4rem;
    background: wheat;
    color: brown;
    font-size: 1.3rem;
    align-items: center;
}

.cart {
    position: relative;
}

.cart img {
    width: 50px;
}

.cart div {
    font-family: SanaSerif, sans-serif;
    position: absolute;
    content: '';
    top: -20px;
    right: 30px;
    font-size: 1.5rem;
    font-weight: bold;
    color: white;
    background: mediumslateblue;
    width: 40px;
    height: 40px;
    padding: 0.5em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header img {
    width: 35px;
    height: 35px;
    cursor: pointer;
    margin-right: 3.8rem;
}
